<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          :to="{name:'accueil'}"
        >
          <!-- logo -->
          <b-img
            :src="appLogoImage"
            width="40"
            height="40"
            alt="logo"
          />

          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Mot de passe oublié?
        </b-card-title>
        <b-card-text
          v-if="message"
          class="mb-2"
          v-text="message"
        />
        <b-card-text
          v-else
          class="mb-2"
        >
          Saisissez votre adresse électronique
          et nous vous enverrons les instructions pour réinitialiser votre mot de passe.
        </b-card-text>

        <!-- form -->

        <validation-observer
          v-if="!message"
          #default="{invalid}"
          ref="simpleRules"
        >
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="forgotPassword"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="form.email"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              size="sm"
              type="submit"
              :disabled="invalid || process"
            >
              Réinitialiser votre mot de passe
              <b-spinner
                v-if="process"
                small
                label="Loading..."
                variant="light"
                class="ml-1"
              />
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Se connecter
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import {
  reactive,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BSpinner, BImg, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
// eslint-disable-next-line import/no-cycle
import useAuthenticate from '@/services/authentification/authService'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BImg,
    BSpinner,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      required,
      email,
    }
  },
  setup() {
    const {
      process, forgot, message,
    } = useAuthenticate()
    const { appName, appLogoImage } = $themeConfig.app

    const form = reactive({ email: '' })

    const forgotPassword = async () => {
      await forgot(form)
    }

    return {
      form,
      forgotPassword,
      process,
      message,
      appName,
      appLogoImage,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$router.push({ name: 'auth-reset-password-v1' })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
